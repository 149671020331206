<template>
  <v-container>
    <v-row class="my-6">
      <v-col lg="9" sm="12" md="12" cols="12" class="mx-auto">
        <v-card>
          <v-card-text>
            <h3 class="font-bold">Signup Your Seller Account</h3>
            <v-row>
              <v-col cols="12">
                <div class="track">
                  <div class="step">
                    <span class="icon"> <v-icon>mdi-account</v-icon> </span>
                    <span class="text">Basic Details</span>
                  </div>
                  <div class="step">
                    <span class="icon"> <v-icon>mdi-map-marker</v-icon></span>
                    <span class="text"> Business Address</span>
                  </div>
                  <div class="step">
                    <span class="icon">
                      <v-icon>mdi-truck-delivery</v-icon>
                    </span>
                    <span class="text"> Warehouse Address</span>
                  </div>
                  <div class="step">
                    <span class="icon">
                      <v-icon>mdi-map-marker-left</v-icon>
                    </span>
                    <span class="text">Return Address</span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <h5 class="font-bold px-4 pb-2">
                  Please fill the form to continue
                </h5>
                <div class="tab">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model.lazy="companyDetails.owner_name"
                        outlined
                        dense
                        label="Owner Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="companyDetails.business_name"
                        outlined
                        dense
                        label="Business Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-file-input
                        v-model="companyDetails.logo"
                        outlined
                        prepend-icon=""
                        dense
                        label="Store/Company Logo"
                        accept="image/*"
                      >
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-file-input
                        v-model="companyDetails.document"
                        outlined
                        prepend-icon=""
                        dense
                        label="Company Registration Document"
                        accept="image/*"
                      >
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model.lazy="companyDetails.phone"
                        outlined
                        dense
                        label="Business Phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="companyDetails.email"
                        outlined
                        dense
                        label="Business Email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="companyDetails.title"
                        outlined
                        dense
                        label="Company Tagline"
                        hint="Best Womens Fashion store in the town"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="companyDetails.description"
                        outlined
                        dense
                        label="Description"
                        hint="Example: A family-run accounting firm that promises you won’t lose sleep over filing your taxes."
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="companyDetails.pan"
                        outlined
                        dense
                        label="PAN or VAT No."
                        hint="VAT No if you have registered your business in VAT Otherwise Enter PAN No"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="companyDetails.office_phone"
                        outlined
                        dense
                        label="Office Phone"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div class="d-flex">
                    <v-btn
                      id="nextBtn"
                      @click="updateGeneralDetails()"
                      color="#d2302c"
                      dark
                      class="px-4 ml-auto"
                      >Save &amp; Continue</v-btn
                    >
                  </div>
                </div>
                <div class="tab">
                  <p class="ml-3 font-bold">Contact</p>
                  <v-row>
                    <v-col lg="6">
                      <v-text-field
                        v-model.lazy="businessAddress.name"
                        label="Full Name"
                        outlined
                        dense
                        hint="Full Name of your Staff that represents your Store"
                      ></v-text-field>
                    </v-col>
                    <v-col lg="6">
                      <v-text-field
                        v-model.lazy="businessAddress.phone"
                        label="Phone Number"
                        outlined
                        dense
                        hint="Phone No of your Staff that represents your Store"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <p class="ml-3 font-bold">Address</p>
                  <v-row>
                    <v-col lg="4" md="6" cols="12">
                      <v-text-field
                        v-model="businessAddress.country"
                        dense
                        outlined
                        disabled
                        label="Country"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="businessAddress.state"
                        :items="businessAddress.states"
                        dense
                        outlined
                        label="State"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="businessAddress.district"
                        :items="businessAddress.districts"
                        dense
                        outlined
                        label="District"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="businessAddress.city"
                        :items="businessAddress.cities"
                        dense
                        outlined
                        label="City"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="businessAddress.area"
                        :items="businessAddress.areas"
                        dense
                        outlined
                        label="Area"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-text-field
                        v-model.lazy="businessAddress.street_address"
                        label="Street Name"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div class="d-flex">
                    <v-btn
                      color="#103755"
                      id="prevBtn"
                      @click="nextPrev(-1)"
                      rounded
                      class="px-4 text-white"
                      >Back</v-btn
                    >
                    <v-btn
                      color="#d2302c"
                      id="nextBtn"
                      @click="updateBusinessAddressDetails()"
                      dark
                      rounded
                      class="px-4 ml-auto"
                      >Save &amp; Continue</v-btn
                    >
                  </div>
                </div>
                <div class="tab">
                  <div>
                    <v-checkbox
                      v-model="businesscopy"
                      dense
                      class="checkbox"
                      label="Copy Business Address Here"
                    >
                    </v-checkbox>
                  </div>
                  <p class="ml-3 font-bold">Contact</p>
                  <v-row>
                    <v-col lg="6" cols="12">
                      <v-text-field
                        v-model.lazy="warehouseAddress.name"
                        label="Full Name"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col lg="6" cols="12">
                      <v-text-field
                        v-model.lazy="warehouseAddress.phone"
                        label="Phone Number"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <p class="ml-3 font-bold">Address</p>
                  <v-row>
                    <v-col lg="4" md="6" cols="12">
                      <v-text-field
                        v-model="warehouseAddress.country"
                        dense
                        outlined
                        disabled
                        label="Country"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="warehouseAddress.state"
                        :items="businessAddress.states"
                        dense
                        outlined
                        label="State"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="warehouseAddress.district"
                        :items="businessAddress.districts"
                        dense
                        outlined
                        label="District"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="warehouseAddress.city"
                        :items="businessAddress.cities"
                        dense
                        outlined
                        label="City"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="warehouseAddress.area"
                        :items="businessAddress.areas"
                        dense
                        outlined
                        label="Area"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-text-field
                        v-model.lazy="warehouseAddress.street_address"
                        label="Street Name"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div class="d-flex">
                    <v-btn
                      color="#103755"
                      id="prevBtn"
                      @click="nextPrev(-1)"
                      rounded
                      class="px-4 text-white"
                      >Back</v-btn
                    >
                    <v-btn
                      color="#d2302c"
                      id="nextBtn"
                      @click="updateWarehouseAddressDetails()"
                      dark
                      rounded
                      class="px-4 ml-auto"
                      >Save &amp; Continue</v-btn
                    >
                  </div>
                </div>
                <div class="tab">
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <v-checkbox
                        v-model="businesscopyForReturn"
                        dense
                        class="checkbox"
                        label="Copy Business Address Here"
                      >
                      </v-checkbox>
                    </div>
                    <div>
                      <v-checkbox
                        v-model="warehousecopy"
                        dense
                        class="checkbox"
                        label="Copy Warehouse Address Here"
                      >
                      </v-checkbox>
                    </div>
                  </div>
                  <p class="ml-3 font-bold">Contact</p>
                  <v-row>
                    <v-col lg="6" cols="12">
                      <v-text-field
                        v-model.lazy="returnAddress.name"
                        label="Full Name"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col lg="6" cols="12">
                      <v-text-field
                        v-model.lazy="returnAddress.phone"
                        label="Phone Number"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <p class="ml-3 font-bold">Address</p>
                  <v-row>
                    <v-col lg="4" md="6" cols="12">
                      <v-text-field
                        v-model="returnAddress.country"
                        dense
                        outlined
                        disabled
                        label="Country"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="returnAddress.state"
                        :items="businessAddress.states"
                        dense
                        outlined
                        label="State"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="returnAddress.district"
                        :items="businessAddress.districts"
                        dense
                        outlined
                        label="District"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="returnAddress.city"
                        :items="businessAddress.cities"
                        dense
                        outlined
                        label="City"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-autocomplete
                        v-model="returnAddress.area"
                        :items="businessAddress.areas"
                        dense
                        outlined
                        label="Area"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="4" md="6" cols="12">
                      <v-text-field
                        v-model.lazy="returnAddress.street_address"
                        label="Street Name"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div class="d-flex">
                    <v-btn
                      color="#103755"
                      id="prevBtn"
                      @click="nextPrev(-1)"
                      rounded
                      class="px-4 text-white"
                      >Back</v-btn
                    >
                    <v-btn
                      color="#d2302c"
                      dark
                      rounded
                      :loading="submitLoading"
                      class="px-4 ml-auto"
                      @click="submitDetails()"
                      >Save &amp; Submit</v-btn
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      submitLoading: false,
      businesscopy: null,
      companyDetails: {
        owner_name: "",
        business_name: "",
        logo: null,
        phone: "",
        title: "",
        description: "",
        email: "",
        pan: "",
        document: null,
        office_phone: "",
      },
      businessAddress: {
        states: ["1", "2", "Bagmati", "Gandaki", "Lumbini", "6", "7"],
        cities: ["Lagankhel", "Sankhamul", "Baneswor", "Thimi"],
        districts: ["Lalitpur", "Bhaktapur", "Kathmandu"],
        areas: ["Satdobato Area", "Langankhel Area", "Nakhipot Area"],
        name: null,
        phone: null,
        street_address: null,
        city: null,
        area: null,
        district: null,
        state: null,
        country: "Nepal",
      },
      warehouseAddress: {
        name: null,
        phone: null,
        street_address: null,
        city: null,
        area: null,
        district: null,
        state: null,
        country: "Nepal",
      },
      returnAddress: {
        name: null,
        phone: null,
        street_address: null,
        city: null,
        area: null,
        district: null,
        state: null,
        country: "Nepal",
      },
      warehousecopy: null,
      businesscopyForReturn: null,
      currentTab: 0,
      n: 0,
    };
  },
  watch: {
    businesscopy() {
      if (this.businesscopy) {
        this.warehousecopy = null;
        this.businesscopyForReturn = null;
        this.copyBusinessAddress();
        console.log("if");
      } else {
        this.resetBusinessAddressForm();
        console.log("else");
      }
    },
    businesscopyForReturn() {
      if (this.businesscopyForReturn) {
        this.warehousecopy = null;
        this.businesscopy = null;
        this.copyBusinessAddressForReturn();
      } else {
        this.resetBusinessAddressForm();
      }
    },
    warehousecopy() {
      if (this.warehousecopy) {
        this.businesscopy = null;
        this.businesscopyForReturn = null;
        this.copyWarehouseAddress();
      } else {
        this.resetWarehouseAddressForm();
      }
    },
  },
  methods: {
    resetBusinessAddressForm() {
      this.businessAddress.name = "";
      this.businessAddress.phone = "";
      this.businessAddress.street_address = "";
      this.businessAddress.city = "";
      this.businessAddress.area = "";
      this.businessAddress.district = "";
      this.businessAddress.state = "";
    },
    resetWarehouseAddressForm() {
      this.warehouseAddress.name = "";
      this.warehouseAddress.phone = "";
      this.warehouseAddress.street_address = "";
      this.warehouseAddress.city = "";
      this.warehouseAddress.area = "";
      this.warehouseAddress.district = "";
      this.warehouseAddress.state = "";
    },
    copyBusinessAddress() {
      console.log("copy business");
      this.warehouseAddress.name = this.businessAddress.name;
      this.warehouseAddress.phone = this.businessAddress.phone;
      this.warehouseAddress.street_address = this.businessAddress.street_address;
      this.warehouseAddress.city = this.businessAddress.city;
      this.warehouseAddress.area = this.businessAddress.area;
      this.warehouseAddress.district = this.businessAddress.district;
      this.warehouseAddress.state = this.businessAddress.state;
    },
    copyBusinessAddressForReturn() {
      this.returnAddress.name = this.businessAddress.name;
      this.returnAddress.phone = this.businessAddress.phone;
      this.returnAddress.street_address = this.businessAddress.street_address;
      this.returnAddress.city = this.businessAddress.city;
      this.returnAddress.area = this.businessAddress.area;
      this.returnAddress.district = this.businessAddress.district;
      this.returnAddress.state = this.businessAddress.state;
    },
    copyWarehouseAddress() {
      this.returnAddress.name = this.warehouseAddress.name;
      this.returnAddress.phone = this.warehouseAddress.phone;
      this.returnAddress.street_address = this.warehouseAddress.street_address;
      this.returnAddress.city = this.warehouseAddress.city;
      this.returnAddress.area = this.warehouseAddress.area;
      this.returnAddress.district = this.warehouseAddress.district;
      this.returnAddress.state = this.warehouseAddress.state;
    },

    updateGeneralDetails() {
      let data = this.companyDetails;
      data.type = null;
      this.$store.commit("setVendorDetails", data);
      this.nextPrev(1);
    },
    updateBusinessAddressDetails() {
      const data = this.businessAddress;
      data.type = "business";
      this.$store.commit("setVendorDetails", data, "business_address");
      this.nextPrev(1);
    },
    updateWarehouseAddressDetails() {
      const data = this.warehouseAddress;
      data.type = "warehouse";
      this.$store.commit("setVendorDetails", data, "warehouse_address");
      this.nextPrev(1);
    },
    updateReturnAddressDetails() {
      const data = this.returnAddress;
      data.type = "return";
      this.$store.commit("setVendorDetails", data, "return_address");
    },
    submitDetails() {
      this.updateReturnAddressDetails();
      this.submit();
    },
    submit() {
      this.submitLoading = true;
      this.$store
        .dispatch("storeVendor")
        .then((res) => {
          //   const data =
          //     "Email: " + res.email + " | " + "Password: " + res.password;
          if (res)
            Swal.fire({
              title: "Success",
              text:
                "Welcome to Sajhadeal Community! You have been registered successfully and plase give us some time to review your data. You will receive an email after confirmation. Thank You!",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Yes",
            }).then((result) => {
              if (result.isConfirmed) {
                location.replace("https://vendor.sajhadeal.com");
              }
            });
          setTimeout(() => {}, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fileUpload(file, type) {
      if (type === "logo") {
        this.logo = file;
      } else {
        this.document = file;
      }
    },
    showTab(n = 0) {
      // This function will display the specified tab of the form ...
      let x = document.getElementsByClassName("tab");
      x[n].style.display = "block";

      // ... and run a function that displays the correct step indicator:
      this.fixStepIndicator(n);
    },
    nextPrev(n) {
      // This function will figure out which tab to display
      var x = document.getElementsByClassName("tab");
      // Exit the function if any field in the current tab is invalid:

      // Hide the current tab:
      x[this.currentTab].style.display = "none";
      // Increase or decrease the current tab by 1:
      this.currentTab = this.currentTab + n;

      // Otherwise, display the correct tab:
      this.showTab(this.currentTab);
    },
    fixStepIndicator(n) {
      let tabelement = document.getElementsByClassName("step");
      tabelement.forEach((el) => {
        el.classList.remove("active");
      });

      tabelement.forEach((item, index) => {
        if (index <= n) {
          item.classList.add("active");
        }
      });
    },
  },
  mounted() {
    this.showTab(this.n);
  },
};
</script>

<style>
.tab {
  display: none;
}
.track {
  position: relative;
  background-color: #ddd;
  height: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  margin-top: 50px;
}

.track .step {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 25%;
  margin-top: -18px;
  text-align: center;
  position: relative;
}

.track .step.active:before {
  background: #ff2432;
}

.track .step::before {
  height: 7px;
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  top: 18px;
}

.track .step.active .icon {
  background: #ff2432;
}
.track .step.active .icon i {
  color: #fff;
}

.track .icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: relative;
  border-radius: 100%;
  background: #ddd;
}
.track .icon i {
  color: #000;
}

.track .step.active .text {
  font-weight: 600;
  color: #000;
}

.track .text {
  display: block;
  margin-top: 7px;
  color: #000;
}
</style>
